.carousel {
  padding-block: 100px;

  > div {
    max-width: 750px;
    margin: auto;
    display: flex;
  }
  .testimonial {
    width: 100% !important;
    align-self: center;
    text-align: center;

    img {
      width: 75px;
      margin: auto;
      margin-bottom: 15px;
    }

    h4 {
      margin-top: 30px;
    }

    h5 {
      margin-block: 5px;
    }
  }

  .slick-arrow::before {
    color: $clr-black;
  }
}

@media (max-width: 750px) {
  .carousel {
    padding-block: 50px;
  }
}

@media (max-width: 850px) {
  .carousel {
    .slick-next {
      right: -100px;
      display: none !important;
    }

    .slick-prev {
      left: -100px;
      display: none !important;
    }
  }
}
