.newsletter {
  padding: 100px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  form {
    align-items: flex-end;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $clr-black;
    padding: 5px 0px;
    width: 350px;
    outline: none;
    border-radius: 0 !important;
  }

  button {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $clr-black;
    align-items: flex-end;
    border-radius: 0 !important;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 500;
  }
  :-moz-placeholder {
    font-style: italic;
    font-weight: 500;
  }
  ::-moz-placeholder {
    font-style: italic;
    font-weight: 500;
  }
  :-ms-input-placeholder {
    font-style: italic;
    font-weight: 500;
  }
}

@media (max-width: 750px) {
  .newsletter {
    padding: 50px;
  }
}

@media (max-width: 500px) {
  .newsletter {
    form {
      width: 100%;
      margin-inline: 25px;
    }
    input {
      width: 100%;
    }
  }
}
