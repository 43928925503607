@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

// Font Sizes
$fs-700: clamp(2.8125rem, 1.553rem + 5.7576vw, 8.75rem);
$fs-600: clamp(2.5rem, 2.1354rem + 1.6667vw, 3.4375rem);
$fs-500: clamp(1.25rem, 1.1528rem + 0.4444vw, 1.5rem);
$fs-400: clamp(0.9375rem, 0.8889rem + 0.2222vw, 1.0625rem);
$fs-300: clamp(0.625rem, 0.6007rem + 0.1111vw, 0.6875rem);

// Font Families
$ff-cormorant: "Cormorant", serif;
$ff-worksans: "Work Sans", sans-serif;

// Colors
$clr-black: #000000;
$clr-dark-gray: #9e9e9e;
$clr-gray: #f4f4f4;
$clr-white: #ffffff;
