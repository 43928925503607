.footer {
  padding-block: 100px 25px;

  .footer-container {
    justify-content: space-between;
    align-items: start;
    gap: 150px;
  }

  .logo {
    gap: 20px;

    img {
      width: 40px;
    }
  }

  .footer-content {
    width: 100%;

    h4 {
      letter-spacing: 2px;
      margin-block: 15px 5px;
    }
  }

  .footer-top {
    justify-content: space-between;
    width: 100%;
    padding-bottom: 50px;
    border-bottom: 1px solid $clr-dark-gray;
  }

  .footer-bottom {
    padding-block: 50px;

    h5 {
      margin-bottom: 5px;
    }
  }

  .copyright {
    text-align: center;
  }

  .socials-holder a:first-child {
    width: 25px;
  }
}

@media (max-width: 1000px) {
  .footer {
    .footer-container {
      flex-direction: column;
      gap: 25px;
    }

    .footer-top {
      padding-bottom: 25px;
    }

    .footer-bottom {
      padding-block: 25px;
    }
  }
}

@media (max-width: 750px) {
  .footer {
    padding-block: 50px 25px;
  }
}

@media (max-width: 650px) {
  .footer {
    .footer-top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;
    }
  }
}

@media (max-width: 400px) {
  .footer {
    text-align: center;

    .logo {
      margin: auto;
    }

    .footer-top {
      grid-template-columns: 1fr;
    }

    .socials-holder {
      width: fit-content;
      margin: auto;
    }
  }
}
