///// RESET /////

// Box Sizing
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

// Set up body
body {
  min-height: 100vh;
  background-color: $clr-white;
  font-family: $ff-cormorant;
  font-size: $fs-400;
  color: $clr-black;
  overflow-x: hidden !important;
  width: 100vw !important;
}

html {
  scroll-behavior: smooth;
}

// Make form elements work easier with
input,
button,
textarea {
  font: inherit;
}

// Remove animations for people who have turned them off
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

///// UTILITY CLASSES /////
// General
.flex {
  display: flex;
  align-items: center;
}

.container {
  max-width: 1300px;
  padding-inline: 16px;
  margin: auto;
}

@media (min-width: 500px) {
  .container {
    padding-inline: 50px;
  }
}

@media (min-width: 700px) {
  .container {
    padding-inline: 19px;
  }
}

@media (min-width: 900px) {
  .container {
    padding-inline: 40px;
  }
}

.bold {
  font-weight: 700;
}
.semi-bold {
  font-weight: 600;
}
.medium {
  font-weight: 500;
}
.italic {
  font-style: italic;
}
.pointer {
  cursor: pointer;
}
.transition {
  transition: all 0.25s;
}
.hidden {
  display: none;
}
.uppercase {
  text-transform: uppercase;
}

// Colors
.clr-white {
  color: $clr-white;
}
.clr-gray {
  color: $clr-dark-gray;
}
.bg-black {
  background-color: $clr-black;
}
.bg-gray {
  background-color: $clr-gray;
}
.bg-white {
  background-color: $clr-white;
}

// Typography
.ff-cormorant {
  font-family: $ff-cormorant;
}
.ff-worksans {
  font-family: $ff-worksans;
}

.fs-700 {
  font-size: $fs-700;
}
.fs-600 {
  font-size: $fs-600;
}
.fs-500 {
  font-size: $fs-500;
}
.fs-400 {
  font-size: $fs-400;
}
.fs-300 {
  font-size: $fs-300;
}
