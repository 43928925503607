.blogs {
  padding-block: 100px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
  }

  h3 {
    letter-spacing: 3px;
  }

  h2 {
    margin-block: 10px 15px;
  }

  .more-container {
    gap: 15px;
    margin-top: 30px;
  }
}

@media (max-width: 800px) {
  .blogs {
    > div {
      grid-template-columns: 1fr 1fr;
      row-gap: 50px;
    }
  }
}

@media (max-width: 750px) {
  .blogs {
    padding-block: 50px;
  }
}

@media (max-width: 400px) {
  .blogs {
    > div {
      grid-template-columns: 1fr;
      row-gap: 30px;
    }
  }
}
