.team {
  padding-block: 100px;

  .team-header {
    max-width: 350px;

    h2 {
      line-height: 0.9;
    }
  }

  .team-card-container {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
  }

  .team-card {
    position: relative;
    background-image: url("../assets/images/banner-image.jpg");
    background-size: cover;
    background-position: center center;
    width: 1fr;
    aspect-ratio: 1/1;

    &:hover > .team-card-content {
      opacity: 1;
    }

    .team-card-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $clr-white;
      opacity: 0;

      img {
        width: 20px;
        height: 20px;
      }

      h3 {
        margin-block: 5px 10px;
      }
    }
  }
  .person-one{
    background-image: url("../assets/images/banner-image.jpg");
  }
  .person-two{
    background-image: url("../assets/images/ridvan-maloku.jpg");
  }
  .person-three{
    background-image: url("../assets/images/arbenita.jpg");
  }
  .person-four{
    background-image: url("../assets/images/elza.jpg");
  }
  .person-five{
    background-image: url("../assets/images/qendrimi.jpg");
  }
  .person-six{
    background-image: url("../assets/images/amar-rexha.jpg");
  }

  .team-footer {
    justify-content: space-between;
    margin-top: 25px;

    p {
      max-width: 400px;
    }
  }

  .contact-button {
    background-color: $clr-gray;
    border: 1px solid transparent;

    &:hover {
      border-color: $clr-black;
      background-color: transparent;
    }
  }
}

.socials-holder {
  gap: 15px;

  a {
    width: 35px;
    height: 35px;
    justify-content: center;
    border: 1px solid $clr-black;
    border-radius: 50%;
  }

  .linkedin,
  .twitter {
    width: 15px;
    height: 15px;
  }
}

@media (max-width: 1000px) {
  .team {
    .team-card-container {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 750px) {
  .team {
    padding-block: 50px;
  }
}

@media (max-width: 650px) {
  .team {
    .team-card-container {
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
  }
  .socials-holder {
    a {
      width: 25px;
      height: 25px;
    }

    .linkedin,
    .twitter {
      width: 12.5px !important;
      height: 12.5px !important  ;
    }
  }
}

@media (max-width: 700px) {
  .team {
    .team-footer {
      p {
        max-width: 300px;
      }
    }
  }
}

@media (max-width: 625px) {
  .team {
    .team-footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      p {
        max-width: 100%;
      }
    }

    .contact-button {
      padding: 10px 20px;
    }
  }
}
