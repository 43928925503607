.contact {
  padding-block: 100px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  .contact-form {
    flex-direction: column;
    max-width: 600px;
    margin: auto;

    div {
      width: 100%;
      gap: 50px;
    }
  }

  .input {
    padding: 5px;
    border: none;
    border-bottom: 1px solid $clr-black;
    width: 100%;
    margin-bottom: 25px;
    outline: none;
  }

  textarea {
    margin-bottom: 0;
    height: 150px;
    resize: none;
  }

  .button-dark {
    background-color: $clr-black;
    color: $clr-white;
    border: 1px solid $clr-black;
    padding-inline: 50px;

    &:hover {
      background-color: $clr-white;
      color: $clr-black;
    }
  }

  input,
  textarea {
    border-radius: 0 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

@media (max-width: 750px) {
  .contact {
    padding-block: 50px;
  }
}

.Toastify__progress-bar {
  background: $clr-black; /* Change this to your desired color */
}

.Toastify__progress-bar--bg.Toastify__progress-bar-theme--light.Toastify__progress-bar--default,
.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
  background: $clr-black !important;
}
