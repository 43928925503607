.header {
  position: absolute;
  top: 30px;
  left: 0;
  gap: 5px;

  .logo {
    width: 40px;
    height: 40px;
  }
}
