.talk {
  background-color: $clr-gray;
  padding-block: 100px;

  div {
    justify-content: space-between;
  }
}

.contact-button {
  padding: 15px 30px;
  background-color: $clr-white;
  color: $clr-black;
  border: none;
}
