.banner {
  position: relative;
  align-items: flex-start;
  margin-bottom: -5px;
  max-width: 2000px;
  padding-bottom: 20px;
  margin: auto;

  .banner-content {
    flex-direction: column;
    align-items: flex-start;
    margin: 200px 25px 0 150px;
    gap: 125px;

    h1 {
      line-height: 0.9;
    }

    p {
      max-width: 500px;
      line-height: 1.3;
      margin-bottom: 30px;
    }

    a {
      letter-spacing: 3px;
      word-spacing: 5px;
      padding: 15px 25px;
      border: 1px solid transparent;

      &:hover {
        background-color: $clr-white;
        border: 1px solid $clr-dark-gray;
      }
    }
  }

  .banner-image {
    width: 950px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1600px) {
  .banner {
    .banner-content {
      gap: 25px;
      margin-left: 100px;
    }
  }
}

@media (max-width: 1100px) {
  .banner {
    .banner-content {
      margin-top: 100px;
      margin-left: 50px;
    }
  }
}

@media (max-width: 1025px) {
  .banner {
    .banner-content {
      gap: 25px;
      h1 {
        text-align: center;
      }
    }
  }
}

@media (max-width: 825px) {
  .banner {
    .banner-content {
      margin-top: 75px;
      margin-left: 25px;
    }
  }
}

@media (max-width: 725px) {
  .banner {
    flex-direction: column;
    text-align: center;

    .banner-content {
      gap: 25px;
      div {
        width: 100%;
      }
      p {
        margin: auto;
        margin-bottom: 25px;
      }
      margin: 100px auto;
    }
    .banner-image {
      width: 100%;
      img {
        width: 90%;
        margin: auto;
      }
    }
  }
}

@media (max-width: 650px) {
  .banner {
    .banner-content {
      margin-bottom: 50px;

      h1 {
        margin-inline: 25px;
      }

      p {
        margin-inline: 25px;
      }
    }
  }
}
