.services {
  flex-direction: column;
  align-items: flex-start;
  padding-block: 100px;
  gap: 100px;

  .services-description {
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }

  .services-title {
    max-width: 600px;

    h2 {
      margin-top: 10px;
      line-height: 0.9;
      margin-bottom: 20px;
    }
  }

  .services-text {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    border-left: 1px solid $clr-black;
    max-width: 500px;
    gap: 30px;
    line-height: 1.5;
  }

  .card-container {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 25px;
  }

  .service-card {
    flex: 1;
    height: 450;
    position: relative;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
    }

    .white-layer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 40px 25px;
      opacity: 0;

      img {
        width: 30px;
      }

      h5 {
        margin-block: 10px 5px;
      }
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      .white-layer {
        opacity: 1;
      }
    }
  }

  .even-card {
    margin-top: 25px !important;
  }
}

@media (max-width: 1000px) {
  .services {
    gap: 75px;

    .services-description {
      width: 100%;
      flex-direction: column;
    }

    .services-title,
    .services-text {
      max-width: 100%;
      width: 100%;
    }

    .services-title {
      padding-bottom: 25px;
    }

    .services-text {
      padding: 0;
      padding-top: 25px;
      border: none;
      border-top: 1px solid $clr-black;
    }

    .card-container {
      gap: 15px;
    }

    .service-card {
      .white-layer {
        padding: 25px 15px;
      }
    }
  }
}

@media (max-width: 750px) {
  .services {
    padding-block: 50px;
    gap: 50px;

    .services-text {
      gap: 15px;
    }

    .card-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
    }

    .service-card {
      .white-layer {
        padding: 40px 25px;
      }
    }
  }
}

@media (max-width: 750px) {
  .services {
    .card-container {
      gap: 25px;
    }
  }
}

@media (max-width: 450px) {
  .services {
    .card-container {
      grid-template-columns: 1fr;
    }

    .service-card {
      margin-inline: 50px;
    }

    .even-card {
      margin-top: 0px !important;
    }
  }
}

@media (max-width: 350px) {
  .services {
    .service-card {
      margin-inline: 25px;
    }
  }
}
